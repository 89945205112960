import { Controller } from "@hotwired/stimulus"
import { refreshCSRFTokens } from "@rails/ujs"
import { useDebounce } from 'stimulus-use'
const morphdom = require('morphdom').default;

export default class extends Controller {
  static targets = ["mobileRecap", "parameterInput", "submitButton", "toggleMobileFormButton", "openMenuButton"]

  static values = {
    refreshPriceUrl: String
  }

  static debounces = ['debouncedRefreshForm']

  connect() {
    if (this.element.dataset.formOpen == 'true') {
      this.openComposerOnMobile()
    }
    useDebounce(this, { wait: 400 })
  }

  // TODO: prevent HTTP call when input value has not changed (i.e user has paused while editing but not make any value changes)
  // Will need the composer_controller to memoize input value

  refreshForm(event) {

    if (this.anyParameterInputsEmpty()) {
      this.disableSubmit()
    } else {
      fetch(this.refreshPriceUrlValue, {
        method: "POST",
        headers: { "Accept": "text/plain" },
        body: new FormData(this.element),
      })
        .then(response => response.text())
        .then((data) => {
          morphdom(this.element, data);
          this.openComposerOnMobile()

          refreshCSRFTokens()
        })
    }
  }

  debouncedRefreshForm(event) {
    this.refreshForm(event)
  }

  anyParameterInputsEmpty() {
    return this.parameterInputTargets.some(input => {
      return input.value.length == 0
    })
  }

  disableSubmit() {
    this.submitButtonTarget.disabled = true
  }

  openComposerOnMobile() {
    this.form.style.transform = 'translateY(10px)'
    this.toggleMobileFormButtonTarget.style.transform = 'rotate(180deg)'
    this.form.setAttribute("data-form-open", "true")
    this.openMenuButtonTarget.style.display = "none"
    // document.querySelector(".crisp-client").classList.add("!hidden")
  }

  showComposerOnMobile(e) {
    let formHeight = this.form.offsetHeight;

    if(this.form.dataset.formOpen === "false") {
      this.openComposerOnMobile()
    } else {
      this.toggleMobileFormButtonTarget.style.transform = 'rotate(0deg)'
      this.form.style.transform = `translateY(${formHeight - 54}px)`
      this.form.setAttribute("data-form-open", "false")
      this.openMenuButtonTarget.style.display = "block"
      // document.querySelector(".crisp-client").classList.remove("!hidden")
    }
  }

  get form() {
    return this.element
  }
}
