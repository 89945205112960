import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["dropdownIcon", "cockpitCard"]

  toggleAccordion(e) {
    this.cockpitCardTarget.classList.toggle('max-h-16')
    this.dropdownIconTarget.classList.toggle('rotate-180')
  }

}