import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ["button", "source"]

  copyToClipboard(e) {
    e.preventDefault();
    navigator.clipboard.writeText(this.sourceTarget.innerText)
    let flashNotice = '<span id="notice" class="font-sans text-sm ml-1"> Copié ! </span>'
    e.currentTarget.insertAdjacentHTML('beforeend', flashNotice)
    setTimeout(() => document.getElementById('notice').remove(), 300)
  }
}
