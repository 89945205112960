// Start StimulusJS
import { Controller } from "@hotwired/stimulus"

// Import and register all TailwindCSS Components
// import { Tabs } from "tailwindcss-stimulus-components"

// application.register('tabs', Tabs)

export default class extends Controller {
  static targets = ['tabs-button']

  switchTabs(e) {
    let oldTab = document.querySelector('.border-primary');
    oldTab.classList.remove('font-bold' ,'text-primary', 'border-primary', 'bg-primary-100', 'hover:bg-primary-200');


    e.currentTarget.classList.add('font-bold' ,'text-primary', 'border-primary', 'bg-primary-100', 'hover:bg-primary-200');
    let count = e.currentTarget.getAttribute('order');
    let actualTab = document.querySelector('.active');
    let newContent = document.getElementById(`features-panel-${count}`);

    actualTab.classList.toggle('hidden');
    actualTab.classList.toggle('active');

    newContent.classList.toggle('active');
    newContent.classList.toggle('hidden');
  }
}
